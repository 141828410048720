body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 18px;
}

h3 {
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
}
